import cn from 'classnames';
import React from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Block from '@/components/brochureV2/primitive/Block/Block';
import type {BlockProps} from '@/components/brochureV2/primitive/Block/types';
import DefaultButton from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
  type ButtonProps,
} from '@/components/brochureV2/primitive/Button/types';
import Image from '@/components/shared/Image/Image';
import type {BlockComponent, LinkComponent} from '@/types';
import {Heading} from '@/components/brochureV2/section/Outcomes/shared';
import type {OutcomesSection} from '@/components/brochureV2/section/Outcomes/types';
import {ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';

interface Outcomes01Props extends OutcomesSection {
  blocks?: BlockComponent[];
  heading?: string;
  kicker?: string;
  link?: LinkComponent;
  subhead?: string;
  blocksClass?: string;
  blockContainerClass?: string;
  blockSize?: BlockProps['size'];
  colorScheme?: ColorSchemeEnum;
  headingClass?: string;
  eyebrowClass?: string;
  buttonTheme?: ButtonTheme;
  customButton?: (props: ButtonProps) => JSX.Element;
  secondaryLink?: LinkComponent;
  secondaryButtonTheme?: ButtonTheme;
}

export default function PosOutcomes01({
  blocks,
  blocksClass,
  blockContainerClass,
  blockSize = 'small',
  buttonTheme = ButtonTheme.Dark,
  colorScheme,
  eyebrowClass,
  heading,
  headingClass,
  kicker,
  link: button,
  subhead,
  customButton,
  secondaryLink,
  secondaryButtonTheme = ButtonTheme.Dark,
}: Outcomes01Props) {
  const Button = customButton ?? DefaultButton;

  const headingProps = {kicker, heading, headingClass, subhead, eyebrowClass};
  const hasHeading = Boolean(kicker) || Boolean(heading) || Boolean(subhead);
  const currentScheme = colorScheme || ColorSchemeEnum.PosLight;

  return (
    <section className={sectionSchemeClasses[currentScheme]}>
      <div className="container">
        <div className="sm:px-gutter sm:mx-[12.5%] md:mx-0 md:px-0">
          {hasHeading ? (
            <>
              <Heading {...headingProps} />
              <Spacer size="4xl" />
            </>
          ) : null}
          {blocks && (
            <AutoGrid
              className="gap-y-2xl"
              cellClass={blocksClass}
              threshold={blocks.length === 4 ? 4 : 3}
            >
              {blocks?.map((block, index) => {
                const icon = block.icon;
                const hasIcon = Boolean(icon?.src);
                const loopKey = `block-${index.toString()}`;
                return (
                  <React.Fragment key={loopKey}>
                    {icon?.src && (
                      <Image
                        src={icon.src}
                        alt={icon.alt}
                        loading="lazy"
                        className="block h-16 w-16 rounded-md object-contain"
                      />
                    )}
                    <AutoGrid.Cell
                      key={heading}
                      className={twMerge(
                        cn({'border-t-2': !hasIcon}, blockContainerClass),
                      )}
                    >
                      {hasIcon ? <Spacer size="sm" /> : <Spacer size="md" />}
                      <Block
                        key={block.heading}
                        {...block}
                        linkTheme={buttonTheme}
                        size={blockSize}
                      />
                    </AutoGrid.Cell>
                  </React.Fragment>
                );
              })}
            </AutoGrid>
          )}
          {button?.url && (
            <>
              <Spacer size="3xl" />
              <div className="flex flex-wrap justify-center">
                <div className="md:inline-block sm:mb-0 mb-[20px] mr-4">
                  <Button
                    type="link"
                    href={button.url}
                    variant={ButtonVariant.Primary}
                    theme={buttonTheme}
                    componentName={button.name || ''}
                    className="text-center block"
                  >
                    {button.text}
                  </Button>
                </div>
                {secondaryLink?.url && (
                  <div className="md:inline-block">
                    <Button
                      type="link"
                      href={secondaryLink.url}
                      variant={ButtonVariant.Secondary}
                      theme={secondaryButtonTheme}
                      componentName={secondaryLink.name || ''}
                      className="text-center block"
                    >
                      {secondaryLink.text}
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
