import {twMerge} from '@/stylesheets/twMerge';
import Block from '@/components/brochureV2/primitive/Block/Block';
import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import Col, {Start, Span} from '@/components/brochureV2/layout/Grid/Col';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import type {OutcomesSection} from '@/components/brochureV2/section/Outcomes/types';
import {AlignmentEnum, ColorSchemeEnum} from '@/enums';
import {ButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import {Heading} from '@/components/brochureV2/section/Outcomes/shared';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import type {BlockComponent, LinkComponent} from '@/types';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';

interface Outcomes03Props extends OutcomesSection {
  buttonTheme?: ButtonTheme;
  linkTheme?: ButtonTheme;
  blockContainerClass?: string;
  colorScheme?: ColorSchemeEnum;
  alignment?: AlignmentEnum;
  blocks?: BlockComponent[];
  heading?: string;
  kicker?: string;
  link?: LinkComponent;
  subhead?: string;
  className?: string;
}
export default function PosOutcomes03({
  alignment,
  blocks,
  heading,
  kicker,
  linkTheme,
  subhead,
  blockContainerClass,
  colorScheme,
}: Outcomes03Props) {
  // component defaults to right & doesn't support center
  const currentAlignment = alignment || AlignmentEnum.Right;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const currentLinkTheme = linkTheme || ButtonTheme.Dark;
  const headingProps = {kicker, heading, subhead};
  const hasHeading = Boolean(kicker) || Boolean(heading) || Boolean(subhead);
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  return (
    <>
      <section
        className={twMerge(
          sectionSchemeClasses[currentScheme],
          'pt-20 pb-16 md:py-20',
        )}
      >
        <TwoColumn className="container pr-[16px]">
          <TwoColumn.Col1 className="col-start-1">
            {hasHeading && (
              <div className="">
                <Heading {...headingProps} />
              </div>
            )}
          </TwoColumn.Col1>

          <TwoColumn.Col2 className="items-center">
            <Col
              className="md:row-span-full"
              start={{
                default: Start.One,
                md: isLeft ? Start.One : Start.Seven,
              }}
              span={{default: Span.Four, sm: Span.Eight, md: Span.Six}}
            >
              {blocks && blocks.length > 0 && (
                <AutoGrid
                  cellClass="sm:basis-full"
                  className=" mx-0 md:mx-0 lg:mx-0"
                  threshold={1}
                >
                  {blocks?.map((block, index) => (
                    <AutoGrid.Cell key={block.heading}>
                      <Block
                        {...block}
                        linkTheme={currentLinkTheme}
                        size="small"
                      />
                      <div
                        className={twMerge(
                          '[&_strong]:font-normal',
                          blockContainerClass,
                          index === blocks?.length - 1
                            ? 'border-b-0'
                            : 'border-b-2 border-white/[.15] mb-4',
                        )}
                      >
                        {index < blocks?.length - 1 && <Spacer size="md" />}
                      </div>
                    </AutoGrid.Cell>
                  ))}
                </AutoGrid>
              )}
            </Col>
          </TwoColumn.Col2>
        </TwoColumn>
      </section>
    </>
  );
}
