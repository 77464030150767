import cn from 'classnames';

import Outcomes, {
  type OutcomesProps,
} from '@/components/brochureV2/section/Outcomes';
import {ColorSchemeEnum} from '@/enums';
import {OutcomesVariant} from '@/components/brochureV2/section/Outcomes/Outcomes';
import type {LinkComponent} from '@/types';
import type {ButtonTheme} from '@/components/brochureV2/primitive/Button/types';

import PosOutcomes01 from './variants/Outcomes01';
import PosOutcomes03 from './variants/Outcomes03';

interface PosOutcomesProps extends OutcomesProps {
  secondaryLink?: LinkComponent;
  secondaryButtonTheme?: ButtonTheme;
  className?: string;
}

export default function PosOutcomes({
  secondaryLink,
  className,
  ...props
}: PosOutcomesProps) {
  const borderClass =
    props.colorScheme === ColorSchemeEnum.PosDark
      ? 'border-white/[.15]'
      : 'border-shade-90/[.15]';

  const isV03 = props.outcomesVariant === OutcomesVariant.V03;

  if (secondaryLink && props.outcomesVariant === OutcomesVariant.V01) {
    return (
      <PosOutcomes01
        {...props}
        secondaryLink={secondaryLink}
        eyebrowClass={cn({
          'text-lime-50': props.colorScheme === ColorSchemeEnum.PosDark,
          'text-lime-70': props.colorScheme !== ColorSchemeEnum.PosDark,
        })}
        blockContainerClass={borderClass}
      />
    );
  } else {
    return isV03 ? (
      <PosOutcomes03
        {...props}
        blockContainerClass={borderClass}
        className={className}
      />
    ) : (
      <Outcomes
        {...props}
        eyebrowClass={cn({
          'text-lime-50': props.colorScheme === ColorSchemeEnum.PosDark,
          'text-lime-70': props.colorScheme !== ColorSchemeEnum.PosDark,
        })}
        className={className}
        blockContainerClass={borderClass}
      />
    );
  }
}
